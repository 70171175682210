import React from 'react'
import star from '../image/star.png'
import starbronza from '../image/star-filledsilver.svg'

const Painting = () => {

    return (
        <div className='Painting' id='services'>
            <div className="container">
                <div className="bosqich_text">
                    <p>2 - Bosqich
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                    </p>
                    <p>Silver
                        <img className='image_star_right' src={starbronza} alt="" />
                    </p>
                </div>
                <h2 className='blog-title'>Zamonaviy  Bo’yash Uslublari</h2>
                <div className='text_painting'><del>990.000</del>
                    <p>480.000 so'm</p></div>
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3 d-flex">
                        <div className="p_box">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/paint4.png" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Qoradan chiqish 2 ta uslub</div>
                                    <div className="p_text_p">Klassik uslubi <br />
                                        Yangicha uslubi <br /> Bu ko'p bosqichli binoni jarayoni yordamida quyuq soch rangidan tabiiyga yaqin (yoki hatto engilroq) o'tishdir.</div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/qora.mp4"></video>

                        </div>
                    </div>

                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/kaskda.jpg" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">6%, 9%, 12% da Xavfsiz ishlash va PH- ko'rsatkichlari</div>
                                    <div className="p_text_p">Ushbu darsda siz - 6% 9% 12% okislitelda PUDRA bn sochni  oldingidan ko’ra tozaroq va Xavfsizroq ochish yo’llarini o’rganasiz .  Sochlar , bosh terisi va maxsulotlarning  PH ko’rsatkchlari juda muxim bo’lib , soch ochartirishda yoki bo’yashda PH ko’rsatkichning axamiyati xaqida xam bilib olasiz .
                                    </div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/ph.mp4"></video>


                        </div>
                    </div>
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/paint3.png" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Shatush va Ombre</div>
                                    <div className="p_text_p">Bu murakkab binoni usuli bo'lib, unda bir rangdan ikkinchisiga silliq o'tish amalga oshiriladi.
                                    </div>
                                </div>
                            </div>
                            {/* <video className='pain_v' controls src="/video/qora.mp4"></video> */}

                        </div>
                    </div>
                    <div className="for_bonus1">
                        <span>* </span>
                        Bonus 10 ta tayyor Formula
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="bosqich_text">
                    <p>3 - Bosqich
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                    </p>
                    <p>Gold
                        <img className='image_star_right' src={star} alt="" />
                    </p>
                </div>
                <h2 className='blog-title'>Zamonaviy Texnikalar</h2>
                <div className='text_painting'><del>1.500.000</del>
                    <p>750.000 so'm</p></div>


                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box">

                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/paint1.png" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Balayaj</div>
                                    <div className="p_text_p">Bu sochlarning bir qismini bo'yash texnikasi bo'lib, unda bo'yoq supurish harakatlari bilan qo'llaniladi, natijada butun uzunlik bo'ylab yumshoq rang cho'ziladi.</div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/balayaj.mp4"></video>


                        </div>
                    </div>

                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/paint2.png" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Airtach</div>
                                    <div className="p_text_p">Milirovaniya texnikasining yangi talqindagi ko’rinishi . Bu uslibda soch qatlamlari fen orqali xavo bn ajratib chiqariladi va folgaga yopiladi . AIRTACH universal texnika bo’lib , uni xar xil ko’rinishda va yo’nalishda bajarish mumkin </div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/airtach.mp4"></video>


                        </div>
                    </div>
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/bra.jpg" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Brazil blond</div>
                                    <div className="p_text_p">Braziliyalik sarg'ish soyalar aralashmasini o'z ichiga oladi, ularning o'yini noyob tasvirni o'rnatadi va soch turmagiga vizual ravishda hajm qo'shadi.</div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/brazilski.mp4"></video>


                        </div>
                    </div>
                    <div className="for_bonus1">
                        <span>* </span>
                        Bonus
                    </div>

                    <div className="col-12">
                        <div className="p_box_2">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/for_sa.jpg" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Fransuz kaskad</div>
                                    <div className="p_text_p"> Boshqa odatiy kaskadlardan qulay bo’lib , bu strijkada sochlar va qatorlar ko’rinishi tabiiyroq buladi . Bu kesish uslubi xamma yuz formalariga mos keladi , albatta uzunlik va proporciyalarni to’g’ri tanlaganda.</div>
                                </div>
                            </div>
                            <video className='pain_v' controls src="/video/fransuz.mp4"></video>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="p_box_2">
                            <div className="p_box_text">
                                <div className="p_img_box">
                                    <img src="/img/pixie2.jpg" alt="" />
                                </div>
                                <div className="p_text">
                                    <div className="p_text_h">Pixie strijka</div>
                                    <div className="p_text_p">Pixie soch turmagi hamma uchun ideal, lekin siz uni yuzning xususiyatlariga qarab tanlashingiz kerak. Ko'p variantlar mavjud va ularning barchasi juda qiziqarli va chiroyli. Pixie soch turmagi kimga ko'proq mos keladi, sizning shaklingizni qanday aniqlash mumkin va pixie asosida qanday soch turmagi paydo bo'ladi, maqolaga qarang!</div>
                                </div>
                            </div>
                            {/* <video className='pain_v' controls src=""></video> */}

                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-5">
                <div className="bosqich_text">
                    <p>4 - Bosqich
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                    </p>
                    <p>Platinum
                        <img className='image_star_right' src={starbronza} alt="" />
                    </p>
                </div>
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box_3">
                            <div className="p_box_3_text">
                                <div className="p_box_3_text_1">
                                    <div className="p_box_3_h">Ranglar Matematikasi</div>
                                    <ul className="p_box_3_p">
                                        <li>Neytralizacia</li>
                                        <li>Konpensacia</li>
                                        <li>Xar-xil kraskalar qilish</li>
                                        <li>Tonirovanie</li>
                                        <li>8-10 gram kraskada soch bo'yash uslubi</li>
                                        <li>Rangli Banyalar</li>
                                        <li>Ranglar doirasi</li>
                                    </ul>
                                    <div className='text_painting'><del>1.100.000</del>
                                        <p>550.000 so'm</p>
                                        <a href='https://t.me/Sherzod_abdullaev88' className="pain_btn">
                                            Bog'lanish</a>
                                    </div>
                                </div>
                                <video controls className='p_box_3_vid' src="/video/marketing.mp4"></video>
                            </div>
                            <div className="p_img_box_3">
                                <img src="/img/cource2.png" alt="" />
                                <div className="p_img_h"><span>* </span> Bonus - Rangli kokteyllar turlari</div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container mt-5 pt-5">
                <div className="bosqich_text">
                    <p>5 - Bosqich
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                        <img className='image_star' src={star} alt="" />
                    </p>
                    <p>Diamond
                        <img className='image_star_right' src={starbronza} alt="" />
                    </p>
                </div>
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 mb-lg-0 mb-3">
                        <div className="p_box_3">
                            <div className="p_box_3_text">
                                <div className="p_box_3_text_1">
                                    <div className="p_box_3_h">Salon marketingi</div>
                                    <ul className="p_box_3_p">
                                        <li>Ish boshlash qadamlari</li>
                                        <li>Mijoz ko'paytirish</li>
                                        <li>Daromad oshirish</li>
                                        <li>Instagram reklama</li>
                                        <li>Instagram kontent</li>
                                        <li>Ishdagi xatolar</li>
                                        <li>Va boshqa ma'lumotlar</li>
                                    </ul>
                                    <div className='text_painting'><del>1.150.000</del>
                                        <p>575.000 so'm</p>
                                        <a href='https://t.me/Sherzod_abdullaev88' className="pain_btn">
                                            Bog'lanish</a>
                                    </div>
                                </div>
                                <video controls className='p_box_3_vid' src="/video/marketing.mp4"></video>


                            </div>



                            <div className="p_img_box_3">
                                <img src="/img/obzor2.png" alt="" />
                                {/* <div className="p_img_h"><span>* </span> Bonus -> Rangli kokteyllar turlari</div> */}
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}

export default Painting
import { useEffect } from 'react'

import CardBlog from './components/CardBlog'
import Cources from './components/Cources'
import Footer from './components/Footer'
import Header from './components/Header'
import Navbar from './components/Navbar'
import Obzor from './components/Obzor'
import Painting from './components/Painting'
import Portfolio from './components/Portfolio'
import VideoBlog from './components/VideoBlog'
import Aos from 'aos';
import Up from './components/UP'
import Comand from './components/Comand'

const App = () => {
  useEffect(() => {
    Aos.init({
        // once: false,
        duration: 1700,
    })
}, [])
  return (
    <>
      <Navbar />
      <Header />
      <Comand/>
      <Cources />
      <Painting />
     
      <VideoBlog />
      <Obzor />
      <Portfolio />
      <CardBlog />
      <Footer />
      <Up/>

    </>
  )
}

export default App
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Footer = () => {
  return (
    <div className='Footer' id='contacts'>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg- 4 text-center">
                    {/* <div className="logo"><a href="/">LOGO</a></div> */}
                    <div className="logo"><img src="/img/logo.png" alt="" /></div>

                    <div className="d-flex justify-content-center">
                        <ul className='list-names'>
                            <li>Artist:</li>
                            <li>Admin:</li>
                            {/* <li>Phone:</li> */}
                        </ul>
                        <ul className='list-info'>
                            <li><a href="/">Sherzod Abdullayev</a></li>
                            <li><a href="@Sherzod_abdullaev88">@abdullaev_admin</a></li>
                            {/* <li><a hr/ef="tel:+998909557121">+998 90 955 71 21</a></li> */}
                        </ul>
                    </div>
               <div className="socials">
                <ul>
                    <li><a target="_blank" href="https://www.instagram.com/sherzodabdullaevs/"><FontAwesomeIcon icon={faInstagram}/></a></li>
                    <li><a href="https://www.youtube.com/@sherzodabdullaev"><FontAwesomeIcon icon={faYoutube}/></a></li>
                    <li><a href="https://www.facebook.com/sherzod.abdullaev.5220"><FontAwesomeIcon icon={faFacebookF}/></a></li>
                </ul>
               </div>

                </div>
            </div>
                   {/* <div className="copyright">©  2022 <a target={"blank"} href="https://kokoagency.uz">kOkO digital agency</a>.</div> */}
        </div>

    </div>
  )
}

export default Footer
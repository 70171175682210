import React from 'react'
import star from '../image/star.png'
import starbronza from '../image/star-filled.svg'
import image1 from '../image/kurss.jpg'

const Cources = () => {
  return (
    <div className='Cources'>
      <div className="container">
        <div className="blur1"></div>
        <div className="path1 d-lg-block d-none">
          <img src="img/path1.png" alt="" />
        </div>
        <div>
          <h2 className='blog-title'>O’quv Programmasi</h2>
          <div className="bosqich_text">
            <p>1 - Bosqich
              <img className='image_star' src={star} alt="" />
            </p>
            <p>Bronza
              <img className='image_star_right' src={starbronza} alt="" />
            </p>
          </div>
          <div className="row myRow align-items-center">

            <div className="col-lg-12 p-0 d-flex flex-lg-row flex-column">
              <div className="info">
                <h3>Koloristika Baza - Boshlang’ich
                  (Umumiy boshlang’ich bilimlar)</h3>
                <ul>
                  <li>Koloristika umumiy qoidalari</li>
                  <li>Ranglar Doirasi</li>
                  <li>Ranglar - Pigmentlar</li>
                  <li>Neytralizaciya</li>
                  <li>Kraskalar haqida</li>
                  <li>Ochartirish </li>
                  <li>Pudralar va oksidantlar</li>
                </ul>
                <div className="btn_design">
                  <del>1.450.000</del>
                  <p>700.000 so'm</p>
                  <div className="buy-btn d-lg-block d-none">

                    <a href="/">
                      Bog'lanish
                    </a>
                  </div>
                </div>
              </div>
           <video className='c_v_1' controls src="/video/boshlangich.mp4"></video>

            </div>
            <div className="col-lg-12 p-0 d-flex mt-4">
              <div className="img">
                <img className='image1_desgin w-100' src={image1} alt="" />
                <div className='text_bonus'><span>*</span>
                  <p>Bonus 10 ta tayyor formulalar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cources
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

import card1 from '../image/com_1.jpg'
import card2 from '../image/com_2.jpg'
import card3 from '../image/com_3.jpg'
import card4 from '../image/com_4.jpg'
import card5 from '../image/com_5.jpg'

const Comand = () => {
    return (
        <>

            <div className="Comand">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h2 className='blog-title'>Bizning Kurslarimizni Tamomlagan O'quvchilarimiz</h2>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    767: {
                                      slidesPerView: 2,
                                      spaceBetween: 20,
                                    },
                                    1024: {
                                      slidesPerView: 3,
                                      spaceBetween: 50,
                                    },
                                  }}


                                navigation={true}
                                loop={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>

                                    <div className="com_box">
                                        <img src={card1} alt="" />
                                        <div className="com_text">
                                            <div className="com_name">
                                                Nilufar Abdullaeva
                                            </div>
                                            <div className="col_soc">
                                                <div className="com_soc_h">Instagram</div>
                                                <a href='https://t.me/nilyufarabdullaevas' className="com_soc_p">@nilyufarabdullaevas
                                                </a>
                                            </div>
                                            <div className="com_h">Oldin - <span>1,500</span>  obunachi
                                            </div>
                                            <div className="com_h">Xozir - <span>26.000</span>  obunachi
                                            </div>
                                            <div className="com_h">Soch bo’yatishga  yozilish
                                            </div>
                                            <div className="com_h_2">Kursdan oldin :
                                            </div>
                                            <div className="com_h">Mijoz kam va varx past
                                            </div>
                                            <div className="com_h_2">Kursdan keyin :
                                            </div>
                                            <div className="com_h">Mijoz xaddan ziyod ko’p
                                            </div>
                                            <div className="com_h">1 oy oldin joy band qilish kk</div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="com_box">
                                        <img src={card2} alt="" />
                                        <div className="com_text">
                                            <div className="com_name">
                                                Aziza Sadikovna

                                            </div>
                                            <div className="col_soc">
                                                <div className="com_soc_h">Instagram</div>
                                                <a href='https://t.me/aziza_sadikovna_' className="com_soc_p">@aziza_sadikovna_

                                                </a>
                                            </div>
                                            <div className="com_h">Oldin -
                                                <span>600</span>  obunachi
                                            </div>
                                            <div className="com_h">
                                                Xozir - <span>14,000 </span>  obunachi
                                            </div>
                                            {/* <div className="com_h">Soch bo’yatishga  yozilish
        </div> */}
                                            <div className="com_h_2">Online kurslardan oldin :

                                            </div>
                                            <div className="com_h">mijoz juda kam  1 xaftada 1 martta

                                            </div>
                                            <div className="com_h_2">Kursdan keyin :
                                            </div>
                                            <div className="com_h">Mijoz juda ko’p,

                                            </div>
                                            <div className="com_h">15 kun oldindan zapis
                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="com_box">
                                        <img src={card3} alt="" />
                                        <div className="com_text">
                                            <div className="com_name">
                                            Robiya Sobirova 

                                            </div>
                                            <div className="col_soc">
                                                <div className="com_soc_h">Instagram</div>
                                                <a href='https://t.me/colorist_robiya_samarqand
' className="com_soc_p">@colorist_robiya_samarqand

                                                </a>
                                            </div>
                                            <div className="com_h">Oldin - <span>800</span>  obunachi
                                            </div>
                                            <div className="com_h">Xozir - <span>10.000</span>  obunachi
                                            </div>
                                            {/* <div className="com_h">Soch bo’yatishga  yozilish
                                            </div> */}
                                            <div className="com_h_2">Kursdan oldin :
                                            </div>
                                            <div className="com_h">Mijoz juda kam 

                                            </div>
                                            <div className="com_h_2">Kursdan keyin :
                                            </div>
                                            <div className="com_h">
Mijoz juda ko’p

                                            </div>
                                            {/* <div className="com_h">1 oy oldin joy band qilish kk</div> */}
                                        </div>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="com_box">
                                        <img src={card4} alt="" />
                                        <div className="com_text">
                                            <div className="com_name">
                                            Nargiza Haytbaevna 

                                            </div>
                                            <div className="col_soc">
                                                <div className="com_soc_h">Instagram</div>
                                                <a href='https://t.me/nargiza_khaytbayevna_colorist' className="com_soc_p">@nargiza_khaytbayevna_colorist

                                                </a>
                                            </div>
                                            <div className="com_h">Oldin - <span>600</span>  obunachi
                                            </div>
                                            <div className="com_h">Xozir - <span>23.000</span>  obunachi
                                            </div>
                                            {/* <div className="com_h">Soch bo’yatishga  yozilish
                                            </div> */}
                                            <div className="com_h_2">Kursdan oldin :
                                            </div>
                                            <div className="com_h">Mijoz Kam 

                                            </div>
                                            <div className="com_h_2">Kursdan keyin :
                                            </div>
                                            <div className="com_h">Mijoz navbat bilan 

                                            </div>
                                            <div className="com_h">Narx Baland 
</div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

<div className="com_box">
    <img src={card5} alt="" />
    <div className="com_text">
        <div className="com_name">
        Iroda Abdullaeva


        </div>
        <div className="col_soc">
            <div className="com_soc_h">Instagram</div>
            <a href='https://t.me/iroda_abdullaeva___' className="com_soc_p">@iroda_abdullaeva___


            </a>
        </div>
        <div className="com_h">Oldin - <span>400</span>  obunachi
        </div>
        <div className="com_h">Xozir - <span>7.400</span>  obunachi
        </div>
        {/* <div className="com_h">Soch bo’yatishga  yozilish
        </div> */}
        <div className="com_h_2">Kursdan oldin :
        </div>
        <div className="com_h">Mijoz Kam 

        </div>
        <div className="com_h_2">Kursdan keyin :
        </div>
        <div className="com_h">Mijoz navbat bilan 

        </div>
        <div className="com_h">Narx Baland 
</div>
    </div>
</div>

</SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Comand
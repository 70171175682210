import React from 'react'

const Obzor = () => {
    return (
        <div className='Obzor'>
            <div className="blur1"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10">
                        <h2 className='blog-title'>Yutuq va Natija uchun - To’lov</h2>
                        <div className="row">
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="col-lg-4 mb-3 mb-lg-0 p-0">
                                <div className="blog">
                                    <h4>Xayot qonuni shundaki</h4>
                                    <div className="line"></div>
                                    <p> Xar bir narsaning o’z to’lovi va badali bor ! Yutuqlarga - Orzularga shunchaki erishib bo’lmaydi.</p>
                                </div>
                                <div className="img d-lg-none d-block">
                                    <img className='w-100' src="img/obzor1.png" alt="" />
                                </div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="col-lg-4 mb-3 mb-lg-0 p-0">
                                <div className="blog">
                                    <h4>Men yutuqqa erishish uchun</h4>
                                    <div className="line"></div>
                                    <p> Mablag’ - Salomatlik - Vaqt - Yaqinlar bn munosabatlar kabi tushunchalarni qurbon qildim - va evaziga o’z kasbimda yutuqqa erisdim !!!</p>
                                </div>
                                <div className="img d-lg-none d-block">
                                    <img className='w-100' src="img/obzor2.png" alt="" />
                                </div>
                            </div>
                            <div data-aos="fade-up"
                                data-aos-duration="3000" className="col-lg-4 mb-3 mb-lg-0 p-0">
                                <div className="blog">
                                    <h4>Siz qanday qurbonlarga tayyorsiz ?</h4>
                                    <div className="line"></div>
                                    <p> Maqsad va Orzuga erishish yo’lida albatta qurbonliklar bo’ladi - bu xayot qonuni.</p>
                                </div>
                                <div className="img d-lg-none d-block">
                                    <img className='w-100' src="img/obzor3.png" alt="" />
                                </div>
                            </div>
                            <div data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine" className="col-8 d-lg-block d-none">
                                <img className='w-100' src="img/obzor1.png" alt="" />
                            </div>
                            <div data-aos="fade-left"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine" className="col-4 d-lg-block d-none">
                                <img className='w-100' src="img/obzor2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Obzor